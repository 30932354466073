#hangaiden #background {
  background: black;
  position: absolute;
  width: 100%;
  top: 42em;
}

@media (min-width: 768px) {
  #hangaiden #background {
    top: 46em;
  }
}
