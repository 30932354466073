.NavBar {
	font-family: 'Styrene B Regular', Fallback, sans-serif;
	margin: 15px 0;
}

.NavBar a {
	color: inherit;
	text-decoration: none;
	font-size: 1em;
}

@media (min-width: 768px) {
	.NavBar a {
		font-size: 1.25em;
	}
}