.AllArtists {
	margin-top: 80px;
}

.AllArtists h1 {
	font-size: 3rem;
}

.AllArtists .list-container {
	margin-top: 80px;
}

.AllArtists .list-item {
	margin-bottom: 40px;
}

.AllArtists a {
	color: inherit;
	text-decoration: none;
}